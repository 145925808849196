import logo from './Dark-Z-Logo-Square.png';
import logoNoBg from './Logo-No-Bg.png';
import logoFull from './zeroz full logo.jpg';
import lol from './MonsterEGirl.png';
import hero_BG from './zeroz_0.png';
import story_BG from './Zeroz_photo12by-AidanCH.png';
import spotify from './spotify.png';
import youtube from './youtube.png';
import insta from './insta.jpg';
import tiktok from './tiktok2.png';
import appleMusic from './apple music.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logoFull} className="header-img" alt="header" />
      {/*<img src={hero_BG} className="app-bg" alt="logo-bg" /> */}
      <header className="App-header">
        <img src={hero_BG} className="app-bg" />
        <div className="albumContainer">
          <img src={lol} className="App-logo" alt="logo" />
          <div className="albumSubtitle">
            <p>Zeroz - Monster E Girl</p>
            <span>From the recent album, <b>Lol</b></span>
          </div>
        </div>
        <div className="button">
          <img src={spotify} alt="spotify" className="icon" />
          Listen on Spotify
          <a
            className="buttonPlay"
            href="https://open.spotify.com/album/0NFSM0oYTBNxuEmCnKsuq2"
            //target="_blank"
            //rel="noopener noreferrer"
          >
            Play
          </a>
        </div>
        <div className="button">
          <img src={appleMusic} alt="spotify" className="icon" />
          Listen on Apple Music
          <a
            className="buttonPlay"
            href="http://itunes.apple.com/album/id/1706811361"
            //target="_blank"
            //rel="noopener noreferrer"
          >
            Play
          </a>
        </div>
        <div className="button">
          <img src={youtube} alt="spotify" className="icon" />
          Watch on Youtube
          <a
            className="buttonPlay"
            href="https://youtu.be/-K5_ImrIq2w"
            //target="_blank"
            //rel="noopener noreferrer"
          >
            Play
          </a>
        </div>

        <div className="button" style={{marginTop:'1rem', justifyContent: 'space-around',}}>
          <p style={{marginLeft:'1rem', fontWeight:'300'}}>We're on</p>
          <div style={{display:'flex'}}>
            <a
              className=""
              href="https://www.youtube.com/@zeroz-band"
              //target="_blank"
              //rel="noopener noreferrer"
            >
              <img src={youtube} alt="youtube" className="icon" />
            </a>
            <a
              className=""
              href="https://www.instagram.com/zeroz_band"
              //target="_blank"
              //rel="noopener noreferrer"
            >
              <img src={insta} alt="insta" className="icon" />
            </a>
            <a
              className=""
              href="https://www.tiktok.com/@zeroz_band"
              //target="_blank"
              //rel="noopener noreferrer"
            >
              <img src={tiktok} alt="tiktok" className="icon" />
            </a>
          </div>

        </div>

      </header>
      <div className="App-header">
        <img src={story_BG} className="story-bg-img" />
        <div className="story-bg">
          <p>Zeroz is a noisey anime inspired band.</p>
          <p>We are barely real, always active, and fully dedicated to telling our story.</p>
          <p>We release music, music videos, hot-garbage, and sooooooon--a mobile app.</p>
          <p>We sincerely hope you have a nice day.</p>
        </div>
      </div>
      <div className="footer">
        <img src={logoNoBg} className="" />
      </div>
    </div>
  );
}

export default App;
